import React, { useEffect, useState } from "react";
import MyCustomSelect from "../../common/elements/myCustomSelect";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { postVisitTracking } from "../../api/VisitTrackingApi";
import { openNotification } from "../../common/elements/myNotification";

const dateFormat = ["YYYY-MM-DD hh:mm A"];

const VisitTrackingForm = ({
  visitTypeOptions,
  statusOptions,
  schedulingDetailOptions,
  editRecord,
  callback,
  provList,
}) => {
  const [formData, setFormData] = useState(
    editRecord?.guid
      ? {
          ...editRecord,
          modifiedBy: sessionStorage.getItem("userName"),
          visitStatusScheduledDate:
            dayjs(editRecord.visitStatusScheduledDate).format(
              "YYYY-MM-DD HH:mm A"
            ) === "1900-01-01 00:00 AM"
              ? ""
              : editRecord.visitStatusScheduledDate,
        }
      : {
          ...editRecord,
        }
  );

  const [visitType, setVisitType] = useState();
  const [visitStatus, setVisitStatus] = useState();
  const [schedulingDetails, setSchedulingDetails] = useState();
  const [renderingProvider, setRenderingProvider] = useState();
  const [pageError, setPageError] = useState({});
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (editRecord.guid) {
      let visitTypeTemp = [];
      if (editRecord?.visitTypeValue) {
        visitTypeTemp = visitTypeOptions?.filter((item) => {
          return item.value === editRecord?.visitTypeValue;
        });
        setVisitType(visitTypeTemp);
      }

      let visitStatusTemp = [];
      if (editRecord?.visitStatusValue) {
        visitStatusTemp = statusOptions?.filter((item) => {
          return item.value === editRecord?.visitStatusValue;
        });
        setVisitStatus(visitStatusTemp);
      }

      let schedulingDetailsTemp = [];
      if (editRecord?.schedulingDetailsValue) {
        schedulingDetailsTemp = schedulingDetailOptions?.filter((item) => {
          return item.value === editRecord?.schedulingDetailsValue;
        });
        setSchedulingDetails(schedulingDetailsTemp);
      }

      let renderingProviderTemp = [];
      if (editRecord?.renderProvider) {
        renderingProviderTemp = provList?.filter((item) => {
          return item.value === editRecord?.renderProvider;
        });
        setRenderingProvider(renderingProviderTemp);
      }

      if (editRecord?.dateTimeCreated === "NaT") {
        setFormData({
          ...formData,
          dateTimeCreated: "",
        });
      }
    }
  }, [editRecord]);

  useEffect(() => {
    if (!editRecord?.visitStatusValue) {
      const defaultVisitStatus = statusOptions?.find(
        (item) => item.label === "To Be Scheduled"
      );

      if (defaultVisitStatus) {
        setVisitStatus([defaultVisitStatus]);
        setFormData({
          ...formData,
          visitStatusLabel: defaultVisitStatus.label,
          visitStatusValue: defaultVisitStatus.value,
        });
      }
    }
  }, [editRecord, statusOptions]);

  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    setPageError({ ...pageError, [name]: value ? "" : "Required" });
  };

  const onVisitTypeChange = (e) => {
    setVisitType(e);
    setFormData({
      ...formData,
      visitTypeLabel: e ? e.label : "",
      visitTypeValue: e ? e.value : "",
    });
    setPageError({ ...pageError, visitTypeValue: e ? "" : "Required" });
    if (e?.value !== "Other") {
      setFormData({
        ...formData,
        visitTypeLabel: e ? e.label : "",
        visitTypeValue: e ? e.value : "",
        visitTypeOther: "",
      });
    }
  };

  const onVisitStatusChange = (e) => {
    setVisitStatus(e);
    setFormData({
      ...formData,
      visitStatusLabel: e ? e.label : "",
      visitStatusValue: e ? e.value : "",
    });
    setPageError({ ...pageError, visitStatusValue: e ? "" : "Required" });
    if (e?.value !== "scheduled") {
      setFormData({
        ...formData,
        visitStatusLabel: e ? e.label : "",
        visitStatusValue: e ? e.value : "",
        visitStatusScheduledDate: "",
      });
    }
    if (e?.value !== "completed") {
      setFormData({
        ...formData,
        visitStatusLabel: e ? e.label : "",
        visitStatusValue: e ? e.value : "",
        visitStatusScheduledDate: "",
      });
    }
    if (e?.value !== "changeOfPCP") {
      setFormData({
        ...formData,
        visitStatusLabel: e ? e.label : "",
        visitStatusValue: e ? e.value : "",
        visitStatusScheduledDate: "",
      });
    }
    if (e?.value !== "dealigned") {
      setFormData({
        ...formData,
        visitStatusLabel: e ? e.label : "",
        visitStatusValue: e ? e.value : "",
        visitStatusScheduledDate: "",
      });
    }
    if (e?.value !== "termedProvider") {
      setFormData({
        ...formData,
        visitStatusLabel: e ? e.label : "",
        visitStatusValue: e ? e.value : "",
        visitStatusScheduledDate: "",
      });
    }
  };

  const onSchedulingDetailsChange = (e) => {
    setSchedulingDetails(e);
    setFormData({
      ...formData,
      schedulingDetailsLabel: e ? e.label : "",
      schedulingDetailsValue: e ? e.value : "",
    });
  };

  const onRenderingProviderChange = (e) => {
    setRenderingProvider(e);
    setFormData({
      ...formData,
      renderProvider: e ? e.value : "",
    });
    // setPageError({ ...pageError, renderProvider: e ? "" : "Required" });
  };

  const onVisitStatusDateChange = (date, dateString) => {
    setFormData({
      ...formData,
      visitStatusScheduledDate: dateString ? dateString : "",
    });
    setPageError({
      ...pageError,
      visitStatusScheduledDate: dateString ? "" : "Required",
    });
  };

  const onSchedulingDetailDateChange = (date, dateString) => {
    setFormData({
      ...formData,
      schedulingDetailDate: dateString ? dateString : "",
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setPageError({});

    let pageErrorTemp = {};

    if (!formData?.visitTypeValue) {
      pageErrorTemp.visitTypeValue = "Required";
    }

    if (formData?.visitTypeValue === "other" && !formData?.visitTypeOther) {
      pageErrorTemp.visitTypeOther = "Required";
    }

    if (!formData?.visitStatusValue) {
      pageErrorTemp.visitStatusValue = "Required";
    }

    const requiredStatuses = [
      "scheduled",
      "completed",
      "changeOfPCP",
      "dealigned",
      "termedProvider",
    ];

    const status = formData?.visitStatusValue;
    if (
      requiredStatuses.includes(status) &&
      !formData?.visitStatusScheduledDate
    ) {
      pageErrorTemp.visitStatusScheduledDate = "Required";
    }

    if (Object.keys(pageErrorTemp).length > 0) {
      setPageError(pageErrorTemp);
      return;
    }

    onSave(e);
  };

  const onSave = async (e) => {
    setDisable(true);
    try {
      const response = await postVisitTracking(formData);
      if (response?.status === 200 && !formData?.guid) {
        openNotification(
          "success",
          "Added",
          "Visit Tracking Added Successfully!"
        );
      } else if (formData?.guid) {
        openNotification(
          "success",
          "Updated",
          "Visit Tracking Updated Successfully!"
        );
      } else {
        openNotification("error", "Error", "Visit Tracking Failed to Add!");
      }
      setFormData(editRecord);

      callback(response);
    } catch (e) {
      return e;
    }
    setDisable(false);
  };
  return (
    <div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold tw-mb-1" htmlFor="visitType">
          Visit Type
        </label>
        <MyCustomSelect
          selectOptions={visitTypeOptions}
          myCallBack={onVisitTypeChange}
          selectedValue={visitType}
          errorMessage={pageError?.visitTypeValue}
          id={"visitType"}
        />
        {formData?.visitTypeValue === "other" && (
          <div className="tw-flex tw-flex-col tw-mt-3">
            <label className="tw-font-bold" htmlFor="nppa">
              Other Visit Type
            </label>
            <input
              className=" form-control"
              type="text"
              maxLength={100}
              id="visitTypeOther"
              value={formData?.visitTypeOther}
              onChange={(e) =>
                handleInputChange("visitTypeOther", e.target.value)
              }
              style={{
                border: pageError?.visitTypeOther ? "1px solid red" : "",
              }}
            />
          </div>
        )}
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold tw-mb-1" htmlFor="visitType">
          Visit Status
        </label>
        <MyCustomSelect
          selectOptions={statusOptions}
          myCallBack={onVisitStatusChange}
          selectedValue={visitStatus}
          errorMessage={pageError?.visitStatusValue}
          id={"visitStatus"}
        />
        {[
          "scheduled",
          "completed",
          "changeOfPCP",
          "dealigned",
          "termedProvider",
        ].includes(formData?.visitStatusValue) && (
          <div className="tw-flex tw-flex-col tw-mt-3">
            <label className="tw-font-bold" htmlFor="hospitalname">
              Date
            </label>
            <DatePicker
              showTime
              use12Hours
              className="form-control"
              onChange={onVisitStatusDateChange}
              format={dateFormat}
              value={
                formData.visitStatusScheduledDate &&
                dayjs(formData.visitStatusScheduledDate).isValid() &&
                dayjs(formData.visitStatusScheduledDate).format(
                  "YYYY-MM-DD hh:mm A"
                ) !== "1900-01-01 12:00 AM"
                  ? dayjs(formData.visitStatusScheduledDate)
                  : null
              }
              style={{
                border: pageError?.visitStatusScheduledDate
                  ? "1px solid red"
                  : "",
              }}
              id="visitStatusDate"
            />
          </div>
        )}
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold tw-mb-1" htmlFor="visitType">
          Scheduling Details
        </label>
        <MyCustomSelect
          selectOptions={schedulingDetailOptions}
          myCallBack={onSchedulingDetailsChange}
          selectedValue={schedulingDetails}
          id={"schedulingDetails"}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold tw-mb-1" htmlFor="visitType">
          Scheduling Detail Date
        </label>
        <DatePicker
          showTime
          use12Hours
          className="form-control"
          onChange={onSchedulingDetailDateChange}
          format={dateFormat}
          value={
            formData.schedulingDetailDate &&
            dayjs(formData.schedulingDetailDate).isValid() &&
            dayjs(formData.schedulingDetailDate).format(
              "YYYY-MM-DD hh:mm A"
            ) !== "1900-01-01 12:00 AM"
              ? dayjs(formData.schedulingDetailDate)
              : null
          }
          id="schedulingDetailDate"
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold tw-mb-1" htmlFor="visitType">
          Rendering Provider
        </label>
        <MyCustomSelect
          selectOptions={provList}
          myCallBack={onRenderingProviderChange}
          selectedValue={renderingProvider}
          id={"renderingProvider"}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold tw-mb-1" htmlFor="visitType">
          Notes
        </label>
        <textarea
          className=" form-control"
          type="text"
          id="notes"
          maxLength={1000}
          value={formData?.notes}
          onChange={(e) => handleInputChange("notes", e.target.value)}
        />
      </div>
      <div className="form-group tw-fixed tw-top-2 tw-right-10">
        <button
          className={
            !disable ? "btn btn-success " : "btn btn-success disabled "
          }
          onClick={onSubmit}
        >
          {editRecord?.guid ? "Update" : "Save"}
        </button>
        <button
          className="btn btn-secondary tw-ml-4"
          onClick={() => callback(false)}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default VisitTrackingForm;
